import React from "react";
import styles from "./index.module.scss";
import github from "../../assets/github.png";
import linkedIn from "../../assets/linkedIn.png";

const Social = () => {
  return (
    <div className={styles.social}>
      <a
        href="https://www.linkedin.com/in/vinothpandian/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className={styles.icon} src={linkedIn} alt="LinkedIn" />
      </a>
      <a
        href="https://github.com/vinothpandian"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className={styles.icon} src={github} alt="Github" />
      </a>
    </div>
  );
};

export default Social;
