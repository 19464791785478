import React, { RefObject } from "react";
import Link from "./link";
import styles from "./sidebar.module.scss";

type Props = {
  aboutMeRef: RefObject<HTMLElement>;
  portfolioRef: RefObject<HTMLElement>;
};

const SideBar = ({ aboutMeRef, portfolioRef }: Props) => (
  <nav className={styles.navigation}>
    <Link href="#about-me" name="about me" refObject={aboutMeRef} />
    <Link href="#portfolio" name="portfolio" refObject={portfolioRef} />
    <a
      href="https://blog.vinoth.info"
      target="_blank"
      rel="noopener noreferrer"
    >
      blog
    </a>
  </nav>
);

export default SideBar;
