import React, { RefObject } from "react";
import { stack as Menu } from "react-burger-menu";
import Link from "./link";
import styles from "./burger.module.scss";
import BurgerMenu from "../../assets/menu.png";
import Close from "../../assets/close.png";

type Props = {
  aboutMeRef: RefObject<HTMLElement>;
  portfolioRef: RefObject<HTMLElement>;
};

const Burger = ({ aboutMeRef, portfolioRef }: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(false);
  };

  const handleStateChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <div className={styles.burger}>
      <Menu
        isOpen={open}
        onStateChange={(state) => handleStateChange(state.isOpen)}
        right
        disableAutoFocus
        customBurgerIcon={<img src={BurgerMenu} alt="Menu" />}
        customCrossIcon={<img src={Close} alt="Close" />}
        burgerButtonClassName={styles.burgerButton}
        crossButtonClassName={styles.closeButton}
        menuClassName={styles.menu}
        bodyClassName={styles.body}
      >
        <div className={styles.links}>
          <Link
            href="#about-me"
            name="about me"
            refObject={aboutMeRef}
            onClick={handleClick}
          />
          <Link
            href="#portfolio"
            name="portfolio"
            refObject={portfolioRef}
            onClick={handleClick}
          />
          <a
            href="https://blog.vinoth.info"
            target="_blank"
            rel="noopener noreferrer"
          >
            blog
          </a>
        </div>
      </Menu>
    </div>
  );
};

export default Burger;
