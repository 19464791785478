import React, { RefObject } from "react";

export interface Props {
  name: string;
  href: string;
  refObject: RefObject<HTMLElement>;
  className?: string;
  onClick?: VoidFunction;
}

const Link = ({ name, href, className = "", refObject, onClick }: Props) => {
  const handleLinkClick = () => {
    onClick?.();

    const nextScreen = refObject?.current;

    if (nextScreen) {
      nextScreen.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    }
  };
  return (
    <a className={className} href={href} onClick={handleLinkClick}>
      {name}
    </a>
  );
};

export default Link;
