import React, { Suspense } from "react";
import Navigation from "./components/Navigation";
import Home from "./screens/Home";
import Loading from "./screens/Loading";

const AboutMe = React.lazy(() => import("./screens/AboutMe"));
const Portfolio = React.lazy(() => import("./screens/Portfolio"));
const Footer = React.lazy(() => import("./screens/Footer"));

function App() {
  const aboutMeRef = React.createRef<HTMLElement>();
  const portfolioRef = React.createRef<HTMLElement>();

  return (
    <>
      <Navigation aboutMeRef={aboutMeRef} portfolioRef={portfolioRef} />
      <Home id="home" />
      <Suspense fallback={<Loading />}>
        <AboutMe ref={aboutMeRef} id="about-me" />
      </Suspense>
      <Suspense fallback="<Loading />">
        <Portfolio ref={portfolioRef} id="portfolio" />
      </Suspense>
      <Suspense fallback="<Loading />">
        <Footer aboutMeRef={aboutMeRef} portfolioRef={portfolioRef} />
      </Suspense>
    </>
  );
}

export default App;
