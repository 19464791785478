import React from "react";
import styles from "./index.module.scss";
import Bullet from "../../assets/bullet.png";

const Loading = () => {
  return (
    <div className={styles.loaderWrapper}>
      <img className={styles.loader} src={Bullet} alt="loading..." />
      <img className={styles.loader} src={Bullet} alt="loading..." />
      <img className={styles.loader} src={Bullet} alt="loading..." />
    </div>
  );
};

export default Loading;
