import React, { RefObject } from "react";
import Media from "react-media";
import SideBar from "./sidebar";
import Burger from "./burger";

type Props = {
  aboutMeRef: RefObject<HTMLElement>;
  portfolioRef: RefObject<HTMLElement>;
};

const Navigation = ({ aboutMeRef, portfolioRef }: Props) => {
  return (
    <Media
      queries={{
        small: "(max-width: 1024px)",
        large: "(min-width: 1024px)",
      }}
    >
      {(matches) => (
        <>
          {matches.large && (
            <SideBar aboutMeRef={aboutMeRef} portfolioRef={portfolioRef} />
          )}

          {matches.small && (
            <Burger aboutMeRef={aboutMeRef} portfolioRef={portfolioRef} />
          )}
        </>
      )}
    </Media>
  );
};

export default Navigation;
