import React from "react";
import { useSpring, animated, config } from "react-spring";
import styles from "./index.module.scss";

const Name = () => {
  const props = useSpring({
    from: {
      top: "0%",
      opacity: 0,
    },
    to: {
      top: "50%",
      opacity: 1,
    },
    config: {
      ...config.slow,
      tension: 160,
    },
  });

  return (
    <animated.header style={props} className={styles.name}>
      <h1 className={styles.title}>
        Vinoth
        <span> Pandian</span>
      </h1>
      <h2 className={styles.subtitle}>
        Research Engineering Manager @ Thomson Reuters
      </h2>
    </animated.header>
  );
};

export default Name;
