import React from "react";
import Name from "../../components/Name";
import Social from "../../components/Social";
import styles from "./index.module.scss";

type Props = {
  id: string;
};

const Home = ({ id }: Props) => {
  return (
    <section id={id} className={styles.home}>
      <Name />
      <Social />
    </section>
  );
};

export default Home;
